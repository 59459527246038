<template>
  <div class="transportation-fares">
    <br/>
    <h1 style="text-align: center;">Transportation Fares</h1>
    <div class="px-2 pb-2 layout justify-end wrap">
      <v-btn v-if="isSuperAdmin" @click="enableFaresByPercentModal">Update fares by %</v-btn>
      <v-tooltip v-if="isEditable" bottom>
        <template v-slot:activator="{ on }">
          <v-btn flat icon>
            <v-icon v-on="on">fal fa-info-circle</v-icon>
          </v-btn>
        </template>
        <span>Perform an audit of all route fares to make sure route id list<br/>references are still valid against the OTP server. Any route fares that are no longer valid are marked as such.</span>
      </v-tooltip>
      <v-btn v-if="isEditable" :disabled="checkingCurrentJobStatus" @click="auditRouteFare()">Start Audit Job</v-btn>
      <v-btn v-if="isEditable" :disabled="checkingCurrentJobStatus || !currentJobStatus || currentJobStatus === 'stop'"
             v-show="currentJobStatus !== 'error'" @click="stopAuditRouteFare()">Stop Audit Job If In Progress
      </v-btn>
      <v-btn v-if="isEditable" flat class="warning" v-show="currentJobStatus === 'error'"
             @click="clearErrorFromRouteFareAudit()">Clear Error
      </v-btn>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn flat icon>
            <div v-if="!checkingCurrentJobStatus">
              <v-icon color="success" v-on="on" v-if="!currentJobStatus">fal fa-check-circle</v-icon>
              <v-icon color="warning" v-on="on" v-if="currentJobStatus === 'busy'">fas fa-sync fa-spin</v-icon>
              <v-icon color="info" v-on="on" v-if="currentJobStatus === 'pending'">fal fa-hourglass-start</v-icon>
              <v-icon color="warning" v-on="on" v-if="currentJobStatus === 'stop'">fas fa-octagon</v-icon>
              <v-icon color="danger" v-on="on" v-if="currentJobStatus === 'error'">fal fa-exclamation-triangle</v-icon>
            </div>
          </v-btn>
        </template>
        <span v-text="currentJobStatusText"/>
      </v-tooltip>
    </div>
    <v-layout row wrap>
      <v-flex sm6 pa-2>
        <form @submit.prevent="searchRouteFares">
          <v-text-field
              solo
              v-model="searchKeywords"
              append-icon="fal fa-search"
              :rules="[v => !v || v.length >= 2 || 'Min 2 characters']"
              :disabled="apiInProgress"
              type="search"
              placeholder="Search by route label"
              @click:append="searchRouteFares"
          />
        </form>
      </v-flex>
      <v-flex sm2 v-if="selectedAgency.value !== 'GPRTU'" pa-2>
        <v-select
            :items="zones"
            label="Zone"
            :value="selectedZone"
            @change="onFilterByZone"
        />
      </v-flex>
      <v-flex :sm2="selectedAgency === 'GPRTU'" :sm4="selectedAgency !== 'GPRTU'" pa-2>
        <v-select
            :items="agencies"
            label="Agency ID"
            :value="selectedAgency"
            @change="onFilterByAgency"
        />
      </v-flex>
      <v-flex sm2 pa-2>
        <v-btn block class="primary" :disabled="apiInProgress || !isEditable" @click="createRouteFareEntry()">
          <v-icon>fal fa-plus</v-icon> &nbsp; Create Route Fare
        </v-btn>
      </v-flex>
    </v-layout>
    <v-form v-if="createdFormExpanded" v-model="isCreatedItemValid" transition="dialog-bottom-transition">
      <v-layout row wrap class="editing-item--background-color">
        <v-flex md2 pa-2>
          <v-btn block :disabled="apiInProgress" @click="loadRouteEditor(editingItem)">
            <v-icon>fas fa-map-marker-edit</v-icon> &nbsp; Set Route
          </v-btn>
        </v-flex>
        <v-flex md2 pa-2>
          <v-text-field
              v-model="editingItem.fareEdit"
              :rules="fareEditRules"
              :disabled="apiInProgress"
              label="Fare"
              placeholder="0.00"
          />
        </v-flex>
        <v-flex md1 pa-2 v-if="editingItem.agencyEdit === 'GPRTU'">
          <v-select
              :items="zones"
              label="Zone"
              :rules="[v => v !== 'none' || 'Zone required']"
              v-model="editingItem.zoneEdit"
              :disabled="apiInProgress"
          />
        </v-flex>
        <v-flex :md3="editingItem.agencyEdit === 'GPRTU'" :md4="editingItem.agencyEdit !== 'GPRTU'" pa-2>
          <v-select
              :items="agencies"
              label="Agency ID"
              :value="editingItem.agencyEdit"
              :disabled="apiInProgress"
          />
        </v-flex>
        <v-flex md4 pa-2>
          <v-btn :disabled="apiInProgress || itemNotEdited || !isCreatedItemValid" @click="saveRouteFareEntry()"
                 color="primary">Save
          </v-btn>
          <v-btn :disabled="apiInProgress || itemNotEdited" @click="cancelItemEdit()" flat small class="text-xs-center">
            reset
          </v-btn>
          <v-btn :disabled="apiInProgress" @click="closeRouteFareEntry()" flat small class="text-xs-center">close
          </v-btn>
        </v-flex>
      </v-layout>
      <v-layout row wrap class="editing-item--background-color">
        <v-flex xs1 pb-1 pl-2>
          Route label:
        </v-flex>
        <v-flex xs3 pb-1>
          <v-text-field
              class="text-field--flat text-field--small"
              prepend-inner-icon="small fa fa-pencil"
              v-model="editingItem.routeLabelFromEdit"
              hide-details
          />
        </v-flex>
        <v-flex xs1 pb-1 style="text-align: center">
          {{ labelSplitter }}
        </v-flex>
        <v-flex xs3 pb-1 pr-2>
          <v-text-field
              class="text-field--flat text-field--small"
              v-model="editingItem.routeLabelToEdit"
              hide-details
          />
        </v-flex>
      </v-layout>
      <v-layout row wrap class="editing-item--background-color">
        <v-flex xs1 pb-2 pl-2>
          Route id list:
        </v-flex>
        <v-flex xs11 pb-2 pr-2>
          <v-text-field
              class="text-field--flat text-field--small"
              :value="editingItem.routeIdListEdit"
              readonly
              hide-details
          />
        </v-flex>
      </v-layout>
    </v-form>
    <v-data-table
        :headers="headers"
        :items="routeFares"
        :pagination.sync="pagination"
        :total-items="totalRouteFares"
        :loading="apiInProgress"
        :rows-per-page-items="[10, 25, 50]"
        @update:pagination="onPagination"
        class="elevation-1"
        must-sort
    >
      <template v-slot:items="props">
        <tr @click="toggleItemEditForm(props)">
          <td>{{ props.item.routeLabel }}</td>
          <td class="text-xs-right">{{ props.item.fareLabel }}</td>
          <td class="text-xs-right">{{ props.item.zone }}</td>
          <td :title="props.item.routeIdList">
            <v-text-field
                class="text-xs-right text-field--flat"
                :value="props.item.routeIdList"
                readonly
                hide-details
            />
          </td>
          <td>
            <v-tooltip v-show="props.item.routeIdListMismatch" top>
              <template v-slot:activator="{ on }">
                <v-icon small class="error--text" v-on="on">fas fa-warning</v-icon>
              </template>
              <span>The route id list geometry hash mismatches the route in the OTP server, run an audit to attempt to find the correct route or manually set a new route
              </span>
            </v-tooltip>
            <v-tooltip v-show="!props.item.routeIdListMismatch" top>
              <template v-slot:activator="{ on }">
                <v-icon small class="info--text" v-on="on">fas fa-check</v-icon>
              </template>
              <span>The route id list geometry hash matches the route in the OTP server
              </span>
            </v-tooltip>
          </td>
          <td class="text-xs-right">{{ props.item.agencyId }}</td>
          <td class="text-xs-right">{{ props.item.dateCreatedLabel }}</td>
          <td class="text-xs-right">{{ props.item.dateUpdatedLabel }}</td>
          <td class="justify-center layout px-0">
            <v-icon small :disabled="props.expanded || !isEditable" @click.stop="deleteItem(props.item)">
              fas fa-trash-alt
            </v-icon>
          </td>
        </tr>
      </template>
      <template v-slot:expand="props">
        <v-form v-model="isEditingItemValid">
          <v-layout row wrap class="editing-item--background-color">
            <v-flex md2 pa-2>
              <v-btn block :disabled="apiInProgress" @click="loadRouteEditor(editingItem)">
                <v-icon>fas fa-map-marker-edit</v-icon> &nbsp; View/Set Route
              </v-btn>
            </v-flex>
            <v-flex md2 pa-2>
              <v-text-field
                  v-model="editingItem.fareEdit"
                  :rules="fareEditRules"
                  :readonly="apiInProgress || !isEditable"
                  label="Fare"
                  placeholder="0.00"
              />
            </v-flex>
            <v-flex md1 pa-2 v-if="editingItem.agencyEdit === 'GPRTU'">
              <v-select
                  :items="zones"
                  label="Zone"
                  :rules="[v => v !== 'none' || 'Zone required']"
                  v-model="editingItem.zoneEdit"
                  :disabled="apiInProgress || !isEditable"
              />
            </v-flex>
            <v-flex :md3="editingItem.agencyEdit === 'GPRTU'" :md5="editingItem.agencyEdit !== 'GPRTU'" pa-2>
              <v-select
                  :items="agencies"
                  label="Agency ID"
                  :value="editingItem.agencyEdit"
                  :disabled="apiInProgress || !isEditable"
              />
            </v-flex>
            <v-flex md4 pa-2>
              <v-btn :disabled="apiInProgress || itemNotEdited || !isEditingItemValid || !isEditable"
                     @click="saveItemEdit(props)" color="primary">Save
              </v-btn>
              <v-btn :disabled="apiInProgress || itemNotEdited || !isEditable" @click="cancelItemEdit()" flat small
                     class="text-xs-center">reset
              </v-btn>
              <v-btn :disabled="apiInProgress" @click="closeItemEditForm(props)" flat small class="text-xs-center">
                close
              </v-btn>
            </v-flex>
          </v-layout>
          <v-layout row wrap class="editing-item--background-color">
            <v-flex xs1 pb-1 pl-2>
              Route label:
            </v-flex>
            <v-flex xs3 pb-1>
              <v-text-field
                  class="text-field--flat text-field--small"
                  prepend-inner-icon="small fa fa-pencil"
                  :readonly="apiInProgress || !isEditable"
                  v-model="editingItem.routeLabelFromEdit"
                  hide-details
              />
            </v-flex>
            <v-flex xs1 pb-1 style="text-align: center">
              {{ labelSplitter }}
            </v-flex>
            <v-flex xs3 pb-1 pr-2>
              <v-text-field
                  class="text-field--flat text-field--small"
                  :readonly="apiInProgress || !isEditable"
                  v-model="editingItem.routeLabelToEdit"
                  hide-details
              />
            </v-flex>
          </v-layout>
          <v-layout row wrap class="editing-item--background-color">
            <v-flex xs1 pb-2 pl-2>
              Route id list:
            </v-flex>
            <v-flex xs11 pb-2 pr-2>
              <v-text-field
                  class="text-field--flat text-field--small"
                  :value="editingItem.routeIdListEdit"
                  readonly
                  hide-details
              />
            </v-flex>
          </v-layout>
        </v-form>
      </template>
    </v-data-table>
    <v-dialog v-model="modalUpdateFaresByPercent" persistent max-width="500px">
      <v-card>
        <v-card-title class="lighten-2 grey text-xs-center">
          <h2>Update Fares By A Percentage</h2>
        </v-card-title>
        <v-card-text class="pa-2">
          <v-alert v-show="updateFaresByPercentError" type="error">{{ updateFaresByPercentError }}</v-alert>
          <div>
            <div class="layout row justify-space-between">
              <div class="layout row justify-start align-center" id="updateFareByPercentTooltipContainer"
                   style="max-width: 35%">
                <v-tooltip attach="#updateFareByPercentTooltipContainer">
                  <template v-slot:activator="{ on }">
                    <v-btn flat icon>
                      <v-icon v-on="on">fal fa-info-circle</v-icon>
                    </v-btn>
                  </template>
                  <span>To add a percentage use a positive number,<br/>to subtract use a negative number</span>
                </v-tooltip>
                <v-text-field type="number" suffix="%" label="Adjust by" v-model="farePercent"/>
              </div>
              <div style="max-width: 55%">
                <v-select
                    :items="agencies"
                    outline
                    label="Agency ID"
                    v-model="selectedAgencyInFarePercent"
                />
              </div>
            </div>
            <div class="my-1 text-xs-center" v-if="countryCurrency && countryCurrency.currencySymbol">
              <small :style="{ opacity: farePercent ? 1 : 0 }">
                Example: {{ countryCurrency.currencySymbol }}2.00 will be updated to
                {{ countryCurrency.currencySymbol }}{{ exampleFarePercent }}
              </small>
            </div>
            <div>
              <v-btn color="primary" block :loading="savingRouteFaresByPercent"
                     :disabled="!farePercent || savingRouteFaresByPercent" @click="updateFaresByPercent">Save
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="layout justify-end">
          <v-btn color="primary" flat @click="modalUpdateFaresByPercent = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
        v-model="routeEditorLoaded">
      <v-card>
        <v-toolbar card dark color="primary" dense height="48">
          <img src="@/assets/logo-badge.png" style="height: 25px" alt=""/>
          <v-spacer/>
          <v-toolbar-title>Route Editor/Selector</v-toolbar-title>
          <v-spacer/>
          <v-toolbar-items>
            <v-btn color="warning" :disabled="!routeIdListFromMap || !isEditable" @click="saveSelectedRouteFromMap">Set
              Route
            </v-btn> &nbsp;
            <v-btn dark flat @click="cancelSelectedRouteFromMap">Cancel</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <st-map
            v-if="routeEditorLoaded"
            container-id="faresMap"
            :mapbox-access-token="mapboxAccessToken"
            :maptiler-key="maptilerKey"
            :on-socket-instance="onSocketInstance"
            :marker-options="markerOptions"
            :route-geometry="editingItem.routeLine"
            :height-offset="48"
            sidebar
            topbar
            is-modal
            :route-id-list="editingItem.routeIdListEdit"
            :api="stMapApis"
            :on-route-selected="onRouteSelected">
        </st-map>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import src from './src'

export default src
</script>
