import _isEqual from 'lodash.isequal'
import maputilities from '@smarttransit/map-utilities'
import { formatCurrency, formatDate, formatTime } from '@smarttransit/common'
import { isAuthorized, getCachedRegions, addAlert } from '@/utilities/helpers'
import { getBusStopsForProfileRoute, getProfileRoute } from '@/services/transportation-profile-routes-service'

import {
  googleGeocodeRequest,
  getCoarseGeolocation,
  getRouteByRouteIdList,
  getBusStopData,
  getRoute
} from '@/services/maps-service'

import {
  D_GET_COUNTRY_CURRENCY,
  D_FIND_ROUTE_FARES,
  D_GET_ROUTE_FARE_TOTAL,
  D_UPDATE_ROUTE_FARE,
  D_CREATE_ROUTE_FARE,
  D_DELETE_ROUTE_FARE,
  D_GET_AUDIT_ROUTE_FARE_JOB_STATUS,
  D_START_AUDIT_ROUTE_FARE_JOB,
  D_STOP_AUDIT_ROUTE_FARE_JOB,
  D_CLEAR_AUDIT_ROUTE_FARE_JOB_ERROR,
  D_UPDATE_ROUTE_FARE_PERCENT
} from '@/utilities/action-types'

import { genericApiRequests } from '@/utilities/axios-factory'
import SocketInstance from '@/utilities/socket-instance'
import { getRouteLineFromRouteFare } from '@/services/route-fare-service'
import _cloneDeep from 'lodash.clonedeep'
import { searchOtpRoutes } from '@/services/st-routes-service'
import { geocodeSearch } from '@/services/bus-stops-service'

export default {
  name: 'transportation-fares',
  props: {
    signedInUser: Object
  },
  data () {
    return {
      apiInProgress: false,
      searchKeywords: '',
      selectedAgency: '',
      selectedZone: '',
      labelSplitter: ` ${maputilities.ROUTE_LABEL_CONNECTOR} `,
      isEditable: false,
      isSuperAdmin: false,
      countryCurrency: null,
      editingItem: null,
      clonedEditingItem: null,
      itemNotEdited: true,
      isEditingItemValid: false,
      isCreatedItemValid: false,
      editFormWatchHandle: null,
      routeEditorLoaded: false,
      createdFormExpanded: false,
      routeIdListFromMap: null,
      routeLabelFromMap: null,
      currentJobStatusText: '',
      currentJobStatus: null,
      checkingCurrentJobStatus: false,
      headers: [],
      routeFares: [],
      totalRouteFares: 0,
      pagination: null,
      zones: [],
      modalUpdateFaresByPercent: false,
      updateFaresByPercentError: '',
      farePercent: '',
      selectedAgencyInFarePercent: '',
      savingRouteFaresByPercent: false,
      fareEditRules: [v => (/[0-9]+\.{1}[0-9]{2}/.test(v + '') || 'Please enter in the format 0.00')],
      agencies: [],
      stMapApis: null,
      mapboxAccessToken: process.env.VUE_APP_MAPBOX_KEY,
      maptilerKey: process.env.VUE_APP_MAPTILER_KEY,
      markerOptions: { startMarkerClass: 'st-map-marker-start', endMarkerClass: 'st-map-marker-end' }
    }
  },
  mounted () {
    this.agencies = this.$store.getters.getAgencies
    this.selectedAgency = 'GPRTU'
    this.selectedZone = 'none'
    this.isEditable = isAuthorized(this.$props.signedInUser, 'admin')
    this.isSuperAdmin = isAuthorized(this.$props.signedInUser, 'superadmin')
    this.zones = [{ text: 'None', value: 'none' }].concat([...'ABCDEFGHIJ'].map(o => ({ text: o, value: o })))

    this.stMapApis = {
      genericApiRequests,
      getRouteByRouteIdList,
      getRoute,
      getBusStopsForProfileRoute,
      getTransportationProfileRoute: getProfileRoute,
      addAlert,
      getBusStops: getBusStopData,
      getCachedRegions,
      googleGeocodeRequest,
      getCoarseGeolocation,
      searchRoutes: searchOtpRoutes,
      geocodeRequest: (keywords, longlat) => geocodeSearch({ keywords, longlat })
    }

    this.headers = [
      {
        text: 'Route Label',
        align: 'left',
        sortable: true,
        value: 'routeLabel'
      },
      {
        text: 'Fare',
        align: 'left',
        sortable: true,
        value: 'fare'
      },
      {
        text: 'Zone',
        align: 'left',
        sortable: true,
        value: 'zone'
      },
      {
        text: 'Route ID List',
        align: 'left',
        sortable: true,
        value: 'routeIdList'
      },
      {
        text: 'Last Route OTP Match',
        align: 'left',
        sortable: true,
        value: 'routeIdListMismatch'
      },
      {
        text: 'Agency ID',
        align: 'left',
        sortable: true,
        value: 'agencyId'
      },
      {
        text: 'Date Created',
        align: 'left',
        sortable: true,
        value: 'dateCreated'
      },
      {
        text: 'Date Updated',
        align: 'left',
        sortable: true,
        value: 'dateUpdated'
      },
      { text: 'Delete', value: 'routeLabel', sortable: false }
    ]

    this.initCountryCurrency().then(() => {
      this.searchRouteFares()
      this.checkJobStatus('forceStart')
    })
  },
  computed: {
    exampleFarePercent () {
      return (2 + (2 * (this.$data.farePercent / 100))).toFixed(2)
    }
  },
  methods: {
    onSocketInstance ({ routerId, onSearchResult, onRouteResult }) {
      return new SocketInstance({
        baseUrl: process.env.VUE_APP_MAP_API_URL,
        prefix: '/v1/socket',
        accessToken: this.$store.getters.getToken?.id,
        onUnAuthorized: () => {
          this.addAlert(`Connection is not authorized, you may need to <a href="/?forward=${this.$route.fullPath}">sign in</a> again`, 'error')
        },
        subscriptions: {
          ['/routes/' + routerId + '/geocode/subscribe']: onSearchResult,
          ['/routes/' + routerId + '/subscribe']: onRouteResult
        }
      })
    },
    runCheckJobStatus (once) {
      this.$store.dispatch(D_GET_AUDIT_ROUTE_FARE_JOB_STATUS).then((result) => {
        // let's refresh the bus stops list if 'busy' was the last state and current state is not 'busy'
        if (this.currentJobStatus === 'busy' && result.status !== 'busy') {
          this.searchRouteFares()
        }
        this.currentJobStatus = result.status
        if (this.currentJobStatus === 'busy') {
          this.currentJobStatusText = 'Audit route fare job in progress'
        } else if (this.currentJobStatus === 'error') {
          this.currentJobStatusText = 'Error in completing the audit route fare job'
        } else if (this.currentJobStatus === 'pending') {
          this.currentJobStatusText = 'Waiting for job to be picked up by process worker'
        } else if (this.currentJobStatus === 'stop') {
          this.currentJobStatusText = 'Job has been stopped'
        } else if (!this.currentJobStatus) {
          this.currentJobStatusText = 'Audit route fare job can be started'
        }
        if (!once) {
          this.checkJobStatus()
        }
      }).catch((err) => {
        addAlert({
          message: `Error in retrieving audit route fare job status: ${err && err.error ? err.error.message : JSON.stringify(err)}`,
          type: 'error'
        })
      })
    },
    enableFaresByPercentModal () {
      this.$data.updateFaresByPercentError = ''
      this.$data.modalUpdateFaresByPercent = true
    },
    async updateFaresByPercent () {
      this.$data.updateFaresByPercentError = ''
      if (!this.$data.selectedAgencyInFarePercent) {
        return alert('Please select an Agency ID')
      }
      if (confirm(`Are you sure you want to change ALL ${this.$data.selectedAgencyInFarePercent} fares by ${this.$data.farePercent}%?`)) {
        try {
          this.$data.savingRouteFaresByPercent = true

          const result = await this.$store.dispatch(D_UPDATE_ROUTE_FARE_PERCENT, {
            percent: (this.$data.farePercent / 100),
            agencyId: this.$data.selectedAgencyInFarePercent
          })

          addAlert({
            message: `Successfully updated ${result.count} ${this.$data.selectedAgencyInFarePercent} fares by ${this.$data.farePercent}%`,
            type: 'success',
            transient: true
          })

          this.searchRouteFares()
          this.$data.savingRouteFaresByPercent = false
          this.$data.modalUpdateFaresByPercent = false
        } catch (err) {
          this.$data.savingRouteFaresByPercent = false
          this.$data.updateFaresByPercentError = `Error updating fares: ${err && err.error ? err.error.message : JSON.stringify(err)}`
        }
      }
    },
    async checkJobStatus (forceStart) {
      if (this.busStopTimeoutHandle) {
        clearTimeout(this.busStopTimeoutHandle)
      }
      if (forceStart) {
        await this.runCheckJobStatus()
      } else {
        this.busStopTimeoutHandle = setTimeout(() => {
          this.runCheckJobStatus()
        }, 30000)
      }
    },
    async auditRouteFare () {
      this.checkingCurrentJobStatus = true
      this.$store.dispatch(D_START_AUDIT_ROUTE_FARE_JOB).then((result) => {
        if (result.success) {
          addAlert({
            message: 'Audit route fare job started',
            type: 'success',
            transient: true
          })
        } else {
          addAlert({
            message: 'Audit route fare job could not be started',
            type: 'error'
          })
        }
        setTimeout(() => {
          this.checkJobStatus('forceStart')
        }, 3000)
      }).catch((err) => {
        addAlert({
          message: `Error in starting audit route fare job: ${err && err.error ? err.error.message : JSON.stringify(err)}`,
          type: 'error'
        })
      }).finally(() => {
        this.checkingCurrentJobStatus = false
      })
    },
    async stopAuditRouteFare () {
      this.checkingCurrentJobStatus = true
      this.$store.dispatch(D_STOP_AUDIT_ROUTE_FARE_JOB).then((result) => {
        if (result.success) {
          addAlert({
            message: 'Audit route fare job stopped',
            type: 'success',
            transient: true
          })
        } else {
          addAlert({
            message: 'The audit route fare job could not be stopped',
            type: 'error'
          })
        }
        setTimeout(() => {
          this.checkJobStatus('forceStart')
        }, 3000)
      }).catch((err) => {
        addAlert({
          message: `Error in stopping audit route fare job: ${err && err.error ? err.error.message : JSON.stringify(err)}`,
          type: 'error'
        })
      }).finally(() => {
        this.checkingCurrentJobStatus = false
      })
    },
    async clearErrorFromRouteFareAudit () {
      this.checkingCurrentJobStatus = true
      this.$store.dispatch(D_CLEAR_AUDIT_ROUTE_FARE_JOB_ERROR).then((result) => {
        if (result.success) {
          addAlert({
            message: 'Audit route fare job error cleared',
            type: 'success',
            transient: true
          })
          this.checkJobStatus('forceStart')
        } else {
          addAlert({
            message: 'Audit route fare job error could not be cleared',
            type: 'error'
          })
        }
      }).catch((err) => {
        addAlert({
          message: `Error in clearing error in audit route fare job: ${err && err.error ? err.error.message : JSON.stringify(err)}`,
          type: 'error'
        })
      }).finally(() => {
        this.checkingCurrentJobStatus = false
      })
    },
    async initCountryCurrency () {
      const agency = this.agencies.find((o) => o.value === this.selectedAgency)
      if (agency.value === 'none') {
        this.countryCurrency = { currencySymbol: '' }
      } else {
        this.countryCurrency = await this.$store.dispatch(D_GET_COUNTRY_CURRENCY, { id: agency.metadata.countryId })
      }
    },
    initEditingItem (item) {
      if (this.editFormWatchHandle) {
        this.editFormWatchHandle()
        this.editFormWatchHandle = null
      }

      this.editingItem = { item, routeIdListEdit: item.routeIdList, routeLine: item.routeLine, routeLabelEdit: item.routeLabel, fareEdit: formatCurrency(item.fare), zoneEdit: item.zone, agencyEdit: item.agencyId }
      const labelSplit = this.editingItem.routeLabelEdit.split(this.labelSplitter)
      this.editingItem.routeLabelFromEdit = labelSplit.length ? labelSplit[0] : ''
      this.editingItem.routeLabelToEdit = labelSplit.length ? labelSplit[1] : ''
      this.clonedEditingItem = { ...this.editingItem }
      this.itemNotEdited = true

      this.editFormWatchHandle = this.$watch('editingItem', (newVal) => {
        this.itemNotEdited = _isEqual(newVal, this.clonedEditingItem)
      }, { deep: true })
    },
    onRouteSelected ({ routeGeometry, routeIdList, from, to } = {}) {
      this.routeIdListFromMap = routeIdList || null
      this.routeLabelFromMap = [from, to].filter(Boolean).join(this.labelSplitter) // route ? maputilities.generateRouteIdListStartDestinationLabel(route.geometry ? route : route.legs) : null
      this.routeLineFromMap = routeGeometry
    },
    saveSelectedRouteFromMap () {
      this.editingItem.routeIdListEdit = this.routeIdListFromMap
      this.editingItem.routeLabelEdit = this.routeLabelFromMap
      const labelSplit = this.editingItem.routeLabelEdit.split(this.labelSplitter)
      this.editingItem.routeLabelFromEdit = labelSplit.length ? labelSplit[0] : ''
      this.editingItem.routeLabelToEdit = labelSplit.length ? labelSplit[1] : ''
      this.editingItem.routeLineEdit = this.routeLineFromMap
      this.routeEditorLoaded = false
    },
    cancelSelectedRouteFromMap () {
      this.routeIdListFromMap = null
      this.routeLabelFromMap = null
      this.routeLineFromMap = null
      this.routeEditorLoaded = false
    },
    createRouteFareEntry () {
      this.initEditingItem({ routeIdList: '', routeLabel: '', fare: formatCurrency(0), zone: this.selectedZone, agencyId: this.selectedAgency })
      this.createdFormExpanded = true
    },
    closeRouteFareEntry () {
      if (this.cancelItemEdit()) {
        if (this.editFormWatchHandle) {
          this.editFormWatchHandle()
          this.editFormWatchHandle = null
        }
        this.createdFormExpanded = false
        this.itemNotEdited = true
      }
    },
    saveRouteFareEntry () {
      this.saveItemEdit().then(() => {
        this.closeRouteFareEntry()
      })
    },
    deleteItem (routeFare) {
      if (confirm(`Confirm deleting (${routeFare.fareLabel}) "${routeFare.routeLabel}"`)) {
        this.$store.dispatch(D_DELETE_ROUTE_FARE, { id: routeFare.id }).then((deletedItem) => {
          addAlert({
            message: `Successfully deleted "${routeFare.routeLabel}"`,
            type: 'success',
            transient: true
          })
          return this.searchRouteFares()
        }).catch((err) => {
          addAlert({
            message: `Error deleting the route fare: ${JSON.stringify(err)}`,
            type: 'error'
          })
        })
      }
    },
    toggleItemEditForm (props) {
      if (!this.createdFormExpanded && this.itemNotEdited) {
        props.expanded = !props.expanded
        if (this.editFormWatchHandle) {
          this.editFormWatchHandle()
          this.editFormWatchHandle = null
        }
        if (this.createFormExpandedWatcher) {
          this.createFormExpandedWatcher()
          this.createFormExpandedWatcher = null
        }
        if (props.expanded) {
          this.createFormExpandedWatcher = this.$watch('createdFormExpanded', (newVal) => {
            if (newVal) {
              props.expanded = false
              this.createFormExpandedWatcher()
              this.createFormExpandedWatcher = null
            }
          })
          this.initEditingItem(props.item)
        } else {
          this.itemNotEdited = true
        }
      }
    },
    closeItemEditForm (props) {
      if (this.cancelItemEdit()) {
        props.expanded = false
        if (this.editFormWatchHandle) {
          this.editFormWatchHandle()
          this.editFormWatchHandle = null
          this.clonedEditingItem = null
        }
        if (this.createFormExpandedWatcher) {
          this.createFormExpandedWatcher()
          this.createFormExpandedWatcher = null
        }
        this.itemNotEdited = true
      }
    },
    saveItemEdit (props = undefined) {
      if ((this.editingItem.item.id && this.isEditingItemValid) || (!this.editingItem.item.id && this.isCreatedItemValid)) {
        if (this.editingItem.routeLabelFromEdit && this.editingItem.routeLabelToEdit) {
          this.editingItem.routeLabelEdit = this.editingItem.routeLabelFromEdit + this.labelSplitter + this.editingItem.routeLabelToEdit
        } else if (!this.editingItem.routeLabelFromEdit || !this.editingItem.routeLabelToEdit) {
          alert('The route label requires a "to" and "from"')
          return
        }
        let promise
        const updatedItem = {
          id: this.editingItem.item.id,
          routeLabel: this.editingItem.routeLabelEdit,
          routeIdList: this.editingItem.routeIdListEdit,
          routeLine: this.editingItem.routeLineEdit,
          fare: Math.round(Number(this.editingItem.fareEdit) * 100),
          agencyId: this.editingItem.agencyEdit,
          zone: this.editingItem.zoneEdit
        }
        if (this.editingItem.item.id) {
          promise = this.$store.dispatch(D_UPDATE_ROUTE_FARE, updatedItem)
        } else {
          promise = this.$store.dispatch(D_CREATE_ROUTE_FARE, updatedItem)
        }
        promise.then((updatedItem) => {
          if (!this.editingItem.item.id) {
            this.searchKeywords = ''
          }
          this.clonedEditingItem = this.editingItem
          this.itemNotEdited = true
          if (props) {
            this.closeItemEditForm(props)
          }
          addAlert({
            message: `Successfully ${this.editingItem.item.id ? 'updated' : 'created'} "${updatedItem.routeLabel}"`,
            type: 'success',
            transient: true
          })

          return this.searchRouteFares()
        })
        promise.catch((err) => {
          addAlert({
            message: `Error ${this.editingItem.item.id ? 'updating' : 'creating'} the route fare: ${JSON.stringify(err)}`,
            type: 'error'
          })
        })
        return promise
      }
    },
    cancelItemEdit () {
      if (!this.itemNotEdited) {
        if (confirm('Are you sure you want to undo your edits?')) {
          this.editingItem = this.clonedEditingItem
          this.itemNotEdited = true
          return true
        }
        return false
      }
      return true
    },
    async loadRouteEditor () {
      try {
        const routeLineGeometry = this.editingItem.item.id ? await getRouteLineFromRouteFare(this.editingItem.item.id) : null
        this.editingItem = { ...this.editingItem, routeLine: routeLineGeometry }
        this.clonedEditingItem = _cloneDeep(this.editingItem)
        this.routeEditorLoaded = true
      } catch (err) {
        addAlert({
          message: `Error in retrieving route line: ${err?.error ? err.error.message : JSON.stringify(err)}`,
          type: 'error'
        })
      }
    },
    onPagination () {
      if (this.countryCurrency) {
        this.searchRouteFares()
      }
    },
    onFilterByZone (val) {
      this.selectedZone = val
      this.searchRouteFares()
    },
    onFilterByAgency (val) {
      this.selectedAgency = val
      this.searchRouteFares()
    },
    async searchRouteFares () {
      const { sortBy, descending, page, rowsPerPage } = this.pagination
      const offset = page === 1 ? 0 : (page * rowsPerPage) - rowsPerPage
      let filter = {
        limit: rowsPerPage,
        offset,
        where: { agencyId: this.selectedAgency }
      }
      if (!sortBy) {
        filter.order = ['dateUpdated DESC', 'dateCreated DESC']
      } else {
        filter.order = `${sortBy} ${descending ? 'DESC' : 'ASC'}`
      }
      if (this.searchKeywords) {
        filter.where.routeLabel = { ilike: `%${this.searchKeywords.replace(/[\s]{2,}/g, ' ').replace(' ', '%')}%` }
      }
      if (this.selectedZone && this.selectedZone !== 'none') {
        filter.where.zone = this.selectedZone
      }
      this.apiInProgress = true
      const [ routeFares, totalRouteFares ] = await Promise.all([
        this.$store.dispatch(D_FIND_ROUTE_FARES, filter),
        this.$store.dispatch(D_GET_ROUTE_FARE_TOTAL, filter)
      ])
      this.routeFares = routeFares.map(o => {
        o.fareLabel = `${this.countryCurrency.currencySymbol}${formatCurrency(o.fare)}`
        o.dateCreatedLabel = `${formatDate(o.dateCreated)}, ${formatTime(o.dateCreated)}`
        o.dateUpdatedLabel = o.dateUpdated ? `${formatDate(o.dateUpdated)}, ${formatTime(o.dateUpdated)}` : ''
        return o
      })
      this.totalRouteFares = totalRouteFares.count
      this.apiInProgress = false
    }
  },
  beforeDestroy () {
    if (typeof this.editFormWatchHandle === 'function') {
      this.editFormWatchHandle()
    }
    if (typeof this.createFormExpandedWatcher === 'function') {
      this.createFormExpandedWatcher()
    }
    if (this.busStopTimeoutHandle) {
      clearTimeout(this.busStopTimeoutHandle)
    }
  }
}
